import React from "react"

const PG_L_HTSOA_WHY_SELLERS_PREFER_CONTENT = {
  cards: [
    {
      title: "The most accurate sales data",
      text: "Get precise stats! Don’t rely on the overhyped sales stats that our competitors display. Realistic sales estimation is the key to making sure that your business gives you a strong net profit.",
    },
    {
      title: "Monthly PDF with current insights into Amazon trends",
      text: "See great products and niches that are currently strong growth opportunities. View sales trends and see what categories are growing to avoid entering a shrinking market.",
    },
    {
      title: "The most affordable prices on the market",
      text: "Unbeatable price for a complete seller’s toolset with full functionality. You’re all set for your Amazon career with this bundle.",
    },
  ],
}

export default PG_L_HTSOA_WHY_SELLERS_PREFER_CONTENT
