import React from "react"
import IntroBgTab from "../../../../assets/images/raster/pages/lp/how-to-sell-on-amazon/HomeIntro/home-video@mid.jpg"
import IntroBgTab2x from "../../../../assets/images/raster/pages/lp/how-to-sell-on-amazon/HomeIntro/home-video@mid1_5x.jpg"
import IntroBgMob from "../../../../assets/images/raster/pages/lp/how-to-sell-on-amazon/HomeIntro/home-video@mob.jpg"
import IntroBgMob2x from "../../../../assets/images/raster/pages/lp/how-to-sell-on-amazon/HomeIntro/home-video@mob1_5x.jpg"
import IntroBgOne from "../../../../assets/images/raster/pages/lp/how-to-sell-on-amazon/HomeIntro/home-intro_how.png"
import webpIntroBgOne from "../../../../assets/images/raster/pages/lp/how-to-sell-on-amazon/HomeIntro/home-intro_how.webp"

const PG_L_HTSOA_HOME_INTRO_CONTENT = {
  IntroBgTab,
  IntroBgTab2x,
  IntroBgMob,
  IntroBgMob2x,
  IntroBgOne,
  webpIntroBgOne,
}

export default PG_L_HTSOA_HOME_INTRO_CONTENT
