import React from "react";
import PG_L_HTSOA_WHY_SELLERS_PREFER_CONTENT from "./index.content";
import "./index.scss";

export const WhySellersPrefer = () => {
  const { cards } = PG_L_HTSOA_WHY_SELLERS_PREFER_CONTENT;

  return (
    <section className="whySellersPrefer">
      <div className="container">
        <div className="whySellersPrefer__title">
          Why Amazon sellers prefer AMZScout
        </div>
        <div className="whySellersPrefer-cards">
          {cards.map(({ title, text }, index) => (
            <div className="whySellersPrefer-cards__item" key={title}>
              <div className="whySellersPrefer-cards__item__number">
                0{index + 1}
              </div>
              <div className="whySellersPrefer-cards__item__title">{title}</div>
              <div className="whySellersPrefer-cards__item__text">{text}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhySellersPrefer;
