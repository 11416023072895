import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { LayoutContext } from "../../../store/context/layout-context";
import { MediaAuthContext } from "../../../store/context/media-auth-context";
import {
  PAGE_OPTIONS,
  PAGES,
  POPUP_TYPES,
  TEXT_PLEASE_TRY_AGAIN,
} from "../../../helpers/constants";
import PropTypes from "prop-types";
import { checkValue, sendEventsCollectEmail } from "../../../helpers";
import { targetStatus } from "../../../helpers/browser";
import EmailCollectAnalyticsController from "../../../controllers/common/emailCollectAnalyticsController";

const EmailCollectContainer = (props) => {
  const {
    children,
    page,
    customLocalSave,
    onSuccess,
    redirect = true,
    customRedirectUrl,
    operation = false,
    customType,
    language = "en",
    customLabel,
    showMobileProPopup,
  } = props;
  const context = useContext(LayoutContext);
  const {
    media: { isTabletOrLess, isNotInitMobile },
    auth: { checkAuth },
  } = useContext(MediaAuthContext);
  const [getFormState, setFormState] = useState({
    email: "",
    isLoading: false,
    error: "",
    opacity: 0,
  });

  const countRef = useRef(context);
  countRef.current = context;
  const analytics = new EmailCollectAnalyticsController(customLabel, page);
  const { CLICK_FORM_OPERATION, CLICK_FORM_TYPE, SOFTWARE, REDIRECTED_URL } =
    PAGE_OPTIONS[PAGES[[page]]];
  const isOperation = operation || !!CLICK_FORM_OPERATION;
  const typeOrOperationValue = isOperation
    ? customType || CLICK_FORM_OPERATION
    : CLICK_FORM_TYPE;
  const software = isOperation ? SOFTWARE : undefined;
  const textPleaseTryAgain =
    TEXT_PLEASE_TRY_AGAIN[language] || TEXT_PLEASE_TRY_AGAIN.en;

  useEffect(() => {
    updateFormState({ opacity: 1 });
  }, [context]);

  const urlRedirect = useMemo(
    () =>
      typeof REDIRECTED_URL === "object"
        ? REDIRECTED_URL[isTabletOrLess ? "MOBILE" : "DESKTOP"]
        : REDIRECTED_URL,
    [page, isTabletOrLess]
  );

  const updateFormState = useCallback((args) => {
    setFormState((prevState) => ({ ...prevState, ...args }));
  }, []);

  const handleEmailChange = (e) => {
    updateFormState({ email: e.target.value });
  };

  const defaultSave = (email) => {
    localStorage.setItem("emailCollect", "true");
    localStorage.setItem("collectData", email);
  };

  const handleEmailSubmit = async (e) => {
    const { captcha, token, isInjected, injection } = countRef.current;
    if (e) {
      e.preventDefault();
    }
    if (!isInjected) {
      injection(true);
    }
    const errorResult = checkValue(
      "email",
      getFormState.email,
      false,
      language
    );
    updateFormState({ error: errorResult });
    if (!errorResult) {
      if (
        typeof window !== "undefined" &&
        !window.location.href.includes("localhost") &&
        !token
      ) {
        if (!captcha.current) {
          updateFormState({ isLoading: true });
          return setTimeout(() => {
            handleEmailSubmit();
          }, 500);
        } else {
          await captcha.current.executeAsync();
          updateFormState({ isLoading: false });
        }
      }
      updateFormState({ isLoading: true });

      sendEventsCollectEmail(
        getFormState.email,
        typeOrOperationValue,
        isOperation,
        software
      )
        .then((response) => {
          if (response.ok) {
            customLocalSave
              ? localStorage.setItem(customLocalSave[0], customLocalSave[1])
              : defaultSave(getFormState.email);
            checkAuth();
            updateFormState({ error: "", email: "" });
            if (
              showMobileProPopup &&
              isNotInitMobile &&
              !context.popupState.open
            ) {
              context.openPopup(POPUP_TYPES.MOBILE_PRO);
              analytics.sendEmailSubmitSuccessEvent();
            } else {
              onSuccess && onSuccess();
              analytics.sendEmailSubmitSuccessEvent();
              redirect &&
                window.open(
                  customRedirectUrl ? customRedirectUrl : urlRedirect,
                  targetStatus() ? "_blank" : "_self"
                );
            }
          } else {
            throw new Error(response.statusText);
          }
        })
        .catch((err) => {
          analytics.sendEmailValidationEvent(err.message);
          updateFormState({ error: textPleaseTryAgain });
          updateFormState({ email: "" });
        })
        .finally(() => updateFormState({ isLoading: false }));
    } else {
      analytics.sendEmailValidationEvent(getFormState.email.length);
      updateFormState({ email: "" });
    }
  };

  const handleKeyUp = (e) => {
    const { injection, isInjected } = countRef.current;
    e.keyCode === 13 && handleEmailSubmit(e);
    e.target.value.length > 0 && !isInjected && injection(true);
  };
  return (
    <>
      {React.cloneElement(children, {
        isLoading: getFormState.isLoading,
        emailValue: getFormState.email,
        onEmailChange: handleEmailChange,
        onSubmit: handleEmailSubmit,
        onError: getFormState.error,
        onKey: handleKeyUp,
        formOpacity: getFormState.opacity,
        redirectUrl: customRedirectUrl ? customRedirectUrl : urlRedirect,
      })}
    </>
  );
};

EmailCollectContainer.propTypes = {
  children: PropTypes.object.isRequired,
  page: PropTypes.string.isRequired,
  customLocalSave: PropTypes.array,
  onSuccess: PropTypes.func,
  redirect: PropTypes.bool,
  operation: PropTypes.bool,
  customRedirectUrl: PropTypes.string,
  customType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default EmailCollectContainer;
