import React, { Fragment } from "react";
import PropTypes from "prop-types";
import PG_L_HTSOA_WHAT_YOU_NEED_BLOCK_CONTENT from "./index.content";
import PointIcon from "../../../../assets/images/vector/components/common/group-copy.svg";
import ButtonCustom from "../../../common/ButtonCustom";
import "./index.scss";

export const WhatYouNeedBlock = ({ handleScroll }) => {
  const { blocks } = PG_L_HTSOA_WHAT_YOU_NEED_BLOCK_CONTENT;

  return (
    <section className="PgLHTSOA-WhatYouNeedBlock">
      <div className="container">
        <div className="PgLHTSOA-WhatYouNeedBlock__title">
          Everything you’ll Ever Need to Start your Amazon Business
        </div>
        {blocks.map(
          (
            { title, text, points, bottomText, isLeftPic, pic, webpPic },
            index
          ) => (
            <Fragment key={title}>
              <div
                className={`PgLHTSOA-WhatYouNeedBlock-block ${
                  isLeftPic ? "leftPic" : ""
                }`}
              >
                <div className="PgLHTSOA-WhatYouNeedBlock-block__info">
                  <div className="PgLHTSOA-WhatYouNeedBlock-block__title">
                    {title}
                  </div>
                  <div className="PgLHTSOA-WhatYouNeedBlock-block__text">
                    {text}
                  </div>
                  <ul className="PgLHTSOA-WhatYouNeedBlock-block__points">
                    {points.map((point) => (
                      <li
                        className="PgLHTSOA-WhatYouNeedBlock-block__points__item"
                        key={point}
                      >
                        <img src={PointIcon} alt="" loading="lazy" />
                        <div>{point}</div>
                      </li>
                    ))}
                  </ul>
                  {bottomText ? (
                    <div className="PgLHTSOA-WhatYouNeedBlock-block__text PgLHTSOA-WhatYouNeedBlock-block__text-bottom">
                      {bottomText}
                    </div>
                  ) : null}
                  <ButtonCustom text="Try For Free" onClick={handleScroll} />
                </div>
                <div className="PgLHTSOA-WhatYouNeedBlock-block__img">
                  <picture>
                    <source srcSet={webpPic} type="image/webp" />
                    <img src={pic} alt="" loading="lazy" />
                  </picture>
                </div>
              </div>
              {index === blocks.length - 1 ? null : <hr />}
            </Fragment>
          )
        )}
      </div>
    </section>
  );
};

WhatYouNeedBlock.propTypes = {
  handleScroll: PropTypes.func,
};

export default WhatYouNeedBlock;
