import Pic1 from "../../../../assets/images/raster/pages/lp/how-to-sell-on-amazon/WhatYouNeedBlock/lp_bg1.png"
import webpPic1 from "../../../../assets/images/raster/pages/lp/how-to-sell-on-amazon/WhatYouNeedBlock/lp_bg1.webp"
import Pic2 from "../../../../assets/images/raster/pages/lp/how-to-sell-on-amazon/WhatYouNeedBlock/lp_bg2.png"
import webpPic2 from "../../../../assets/images/raster/pages/lp/how-to-sell-on-amazon/WhatYouNeedBlock/lp_bg2.webp"
import Pic3 from "../../../../assets/images/raster/pages/lp/how-to-sell-on-amazon/WhatYouNeedBlock/lp_bg3.png"
import webpPic3 from "../../../../assets/images/raster/pages/lp/how-to-sell-on-amazon/WhatYouNeedBlock/lp_bg3.webp"
import Pic4 from "../../../../assets/images/raster/pages/lp/how-to-sell-on-amazon/WhatYouNeedBlock/lp_bg4.png"
import webpPic4 from "../../../../assets/images/raster/pages/lp/how-to-sell-on-amazon/WhatYouNeedBlock/lp_bg4.webp"

const PG_L_HTSOA_WHAT_YOU_NEED_BLOCK_CONTENT = {
  blocks: [
    {
      title: "Learn with Amazon Seller Step-By-Step Course",
      text: "Full and actionable knowledge about how to start selling profitable products in less than 3 months:",
      points: [
        "How to select a product guide.",
        "How to source and ship products manually.",
        "How to sell your product: listing and use of paid ads.",
        "How to get reviews early in a safe and legal way.",
        "The legal aspects of a business on Amazon.",
      ],
      pic: Pic1,
      webpPic: webpPic1,
      isLeftPic: false,
    },
    {
      title: "Check What Products Could be Profitable",
      text: "AMZScout Product Database has 520 million products listed so that you can easily pick out the winners.",
      points: [
        "Find high potential product opportunities in minutes.",
        "Make sure the products are going to be profitable with accurate sales stats.",
        "Track live day-to-day product performance before investing in it.",
      ],
      pic: Pic2,
      webpPic: webpPic2,
      isLeftPic: true,
    },
    {
      title: "Find What Exactly to Sell on Amazon",
      text: "AMZScout has a whole range of tools to make sure you find a profitable product that sells. With PRO Extension you can:",
      points: [
        "See how many items of any product on Amazon were sold. ",
        "View the history of its sales to see if you should sell it too.",
        "Check 16 other parameters that help you validate if a product is worth selling.",
      ],
      bottomText:
        "Sound awesome? Go check out up to 14 products you are considering selling right now with our free PRO Extension Trial.",
      pic: Pic3,
      webpPic: webpPic3,
      isLeftPic: false,
    },
    {
      title: "Help Customers Find your Product",
      text: "To help customers find your product, you have to have the right keywords in your listing.",
      points: [
        "Instantly see competitors’ keywords to copy and catch up with them.",
        "Get thousands of relevant keywords for your listing and ads to attract even more shoppers.",
        "Track how your product ranks for keywords to lower your advertisement costs.",
      ],
      bottomText:
        "With Amazon Keywords Search, Reverse ASIN Lookup, and Keyword Tracker you are completely prepared for this task.",
      pic: Pic4,
      webpPic: webpPic4,
      isLeftPic: true,
    },
  ],
}

export default PG_L_HTSOA_WHAT_YOU_NEED_BLOCK_CONTENT
