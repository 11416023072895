import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

export const RatingReviewTrustpilot = ({ reviews, image }) => {
  const {
    TrustPilotIcon,
    webpTrustPilotIcon,
    TrustFourStarsIcon,
    webpTrustFourStarsIcon,
    PostStarsIcon,
    webpPostStarsIcon,
  } = image;

  return (
    <div className="PgLHTSOA-RatingReviewTrustpilot">
      <div className="PgLHTSOA-RatingReviewTrustpilot-reviews">
        {reviews.map(({ title, date, info, infoDOM }) => (
          <div className="PgLHTSOA-RatingReviewTrustpilot-post" key={title}>
            <div className="PgLHTSOA-RatingReviewTrustpilot-post__header">
              <picture>
                <source srcSet={webpPostStarsIcon} type="image/webp" />
                <img
                  src={PostStarsIcon}
                  alt=""
                  className="PgLHTSOA-RatingReviewTrustpilot-post__stars"
                  loading="lazy"
                />
              </picture>
              <div className="PgLHTSOA-RatingReviewTrustpilot-post__date">
                {date}
              </div>
            </div>
            <p className="PgLHTSOA-RatingReviewTrustpilot-post__title">
              {title}
            </p>
            {info ? (
              <p className="PgLHTSOA-RatingReviewTrustpilot-post__info">
                {info}
              </p>
            ) : null}
            {infoDOM ? (
              <div className="PgLHTSOA-RatingReviewTrustpilot-post__info">
                {infoDOM}
              </div>
            ) : null}
          </div>
        ))}
      </div>
      <div className="PgLHTSOA-RatingReviewTrustpilot-trust">
        <picture>
          <source srcSet={webpTrustPilotIcon} type="image/webp" />
          <img
            className="PgLHTSOA-RatingReviewTrustpilot-trust__pilot"
            src={TrustPilotIcon}
            alt=""
            loading="lazy"
          />
        </picture>
        <picture>
          <source srcSet={webpTrustFourStarsIcon} type="image/webp" />
          <img
            className="PgLHTSOA-RatingReviewTrustpilot-trust__stars"
            src={TrustFourStarsIcon}
            alt=""
            loading="lazy"
          />
        </picture>
        <span className="PgLHTSOA-RatingReviewTrustpilot-trust__info">
          Excellent
        </span>
      </div>
    </div>
  );
};

RatingReviewTrustpilot.propTypes = {
  reviews: PropTypes.array.isRequired,
};

export default RatingReviewTrustpilot;
