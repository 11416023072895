import { trackGa } from "../../tracking/googleAnalytics";
import { PAGE_OPTIONS, PAGES } from "../../helpers/constants";

class EmailCollectAnalyticsController {
  constructor(customLabel, page) {
    this.value = "";
    this.customLabel = customLabel;
    this.page = page;
  }

  createCategory(software, SubSoftware) {
    this.category = `${software}|${SubSoftware}`;
  }

  createLabel() {
    this.href = typeof window !== "undefined" ? window.location.href : null;
    this.label = this.customLabel
      ? `${this.href}${this.customLabel}`
      : this.href;
  }

  createAction(status, err) {
    this.action = status ? "Success" : "Error";
    return err ? `${this.action}|${err}` : this.action;
  }

  createSendEvent(status, err, software, SubSoftware) {
    this.createCategory(software, SubSoftware);
    this.createAction(status, err);
    this.createLabel();
    this.send();
  }

  send() {
    trackGa(this.action, {
      category: this.category,
      label: this.label,
      value: this.value,
      transport_type: "beacon",
    });
  }

  sendEmailValidationEvent(length) {
    const err = length > 0 ? "Email Validation Failed" : "Empty Input";
    this.createSendEvent(
      false,
      err,
      PAGE_OPTIONS[PAGES[this.page]].SOFTWARE,
      "EmailForm"
    );
  }

  sendEmailSubmitErrorEvent(err) {
    this.createSendEvent(
      false,
      err,
      PAGE_OPTIONS[PAGES[this.page]].SOFTWARE,
      "EmailForm"
    );
  }

  sendEmailSubmitSuccessEvent() {
    this.createSendEvent(
      true,
      null,
      PAGE_OPTIONS[PAGES[this.page]].SOFTWARE,
      "EmailForm"
    );
  }
}

export default EmailCollectAnalyticsController;
