import React, { useContext } from "react";
import { MediaAuthContext } from "../../../store/context/media-auth-context";
import PropTypes from "prop-types";
import { PAGES } from "../../../helpers/constants";
import { getTrueClasses } from "../../../helpers";
import track from "../../../tracking/analytics";
import "./StandardEmailCollect/index.scss";

const RedirectedButton = (props) => {
  const {
    title,
    href,
    isIntro,
    background = "gradient",
    isTopText = true,
    page,
    customClass,
    isShowText = false,
    staticTopTitle,
  } = props;
  const {
    media: { isTabletOrLess },
  } = useContext(MediaAuthContext);

  const urlRedirect =
    typeof href === "object"
      ? href[isTabletOrLess ? "MOBILE" : "DESKTOP"]
      : href;

  const handleClick = () => {
    track("Index", urlRedirect, "TryForFree", undefined, "LANDING");
  };
  const showTopText = isShowText && isTopText && (
    <p>Please click the button below if you are not redirected</p>
  );

  return (
    <div
      className={getTrueClasses(
        "installView",
        "emailCollect",
        isIntro ? "left" : "",
        page ? `installView-${PAGES[page].toLowerCase()}` : "",
        customClass
      )}
    >
      {staticTopTitle ? <p>{staticTopTitle}</p> : showTopText}
      <a
        className={`bt-orange bg-${background}`}
        onClick={handleClick}
        rel="nofollow"
        href={urlRedirect}
        target="_blank"
      >
        {title}
      </a>
    </div>
  );
};

RedirectedButton.propTypes = {
  title: PropTypes.string,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  page: PropTypes.string,
  isIntro: PropTypes.bool,
  background: PropTypes.string,
  isTopText: PropTypes.bool,
  customClass: PropTypes.string,
  isShowText: PropTypes.bool,
  staticTopTitle: PropTypes.string,
};

export default RedirectedButton;
