import React from "react";
import EmailCollectContainer from "./EmailCollectContainer";
import PropTypes from "prop-types";
import ControlsEmailCollect from "../../../components/common/Forms/ControlsEmailCollect";

const ControlsEmailCollectContainer = (props) => {
  const { btnText, btnSize, position, smallGreyBottomText, page, onSuccess } =
    props;
  return (
    <EmailCollectContainer page={page} onSuccess={onSuccess}>
      <ControlsEmailCollect
        btnText={btnText}
        btnSize={btnSize}
        position={position}
        smallGreyBottomText={smallGreyBottomText}
      />
    </EmailCollectContainer>
  );
};

ControlsEmailCollectContainer.propTypes = {
  btnText: PropTypes.string,
  btnSize: PropTypes.string,
  position: PropTypes.string,
  smallGreyBottomText: PropTypes.string,
  page: PropTypes.string,
  onSuccess: PropTypes.func,
};

export default ControlsEmailCollectContainer;
