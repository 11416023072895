import React from "react"
import TrustPilotIcon from "../../../../assets/images/raster/components/common/trustpilot.png"
import webpTrustPilotIcon from "../../../../assets/images/raster/components/common/trustpilot.webp"
import TrustFourStarsIcon from "../../../../assets/images/raster/components/common/trustfourstars.png"
import webpTrustFourStarsIcon from "../../../../assets/images/raster/components/common/trustfourstars.webp"
import PostStarsIcon from "../../../../assets/images/raster/pages/lp/how-to-sell-on-amazon/RatingReviewTrustpilot/postStars.png"
import webpPostStarsIcon from "../../../../assets/images/raster/pages/lp/how-to-sell-on-amazon/RatingReviewTrustpilot/postStars.webp"

const PG_L_HTSOA_RATING_REVIEW_TRUSTPILOT_CONTENT = {
  image: {
    TrustPilotIcon,
    webpTrustPilotIcon,
    TrustFourStarsIcon,
    webpTrustFourStarsIcon,
    PostStarsIcon,
    webpPostStarsIcon,
  },
  reviews1: [
    {
      title: "Best tool on the Earth ",
      info: "This is one of the best tools I have used. And always updated with latest features to equip Amazon sellers to combat the evolving Amazon market. ",
      date: "Aug 22. 2020 ",
    },
    {
      title: "This product is fantastic",
      info: "This product is fantastic. It works exactly as advertised. If you are looking into starting your own Amazon store, this product is a must. I compared it to Jungle Scout and it works better and faster and provides more accurate results than Jungle Scout. Their customer support team is very helpful as well and easy to talk to. Overall, this is a 5 star product. It's a must have and you won't regret choosing this product.",
      date: "Jul 6. 2020 ",
    },
    {
      title: "AMZScout is a great tool to use for...",
      info: "AMZScout is a great tool to use for anyone starting or someone who is experienced. I had the trial but decided it was well worth the money. It really does pay for itself. To make money you have you invest in yourself and in your business! You won't regret it!",
      date: "Sep 4, 2020",
    },
  ],
  reviews2: [
    {
      title: "E-A-S-Y : Tool ",
      infoDOM: (
        <>
          E-A-S-Y : Tool for Amazon Seller, <br />- Easy to Use. <br />-
          Accuracy Data. <br />- Systematically Excellent. <br />- You can touch
          it.
        </>
      ),
      date: "Mar 20, 2020",
    },
    {
      title: "I ve been using AMZScout for few months...",
      info: "I ve been using AMZScout for few months now, It's superb, easy to use, bring perfect results, it is very easy to handle and use, more accurate data, I recommend that it is better than helium and other softwares out there.",
      date: "Jan 10, 2020",
    },
    {
      title: "Excellent service",
      info: "Excellent service! I've been using it for some time now and I'm impressed! The data is so accurate, the customer service is very kind and helpful.",
      date: "Aug 12, 2020",
    },
  ],
}

export default PG_L_HTSOA_RATING_REVIEW_TRUSTPILOT_CONTENT
