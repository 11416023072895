import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const ControlsEmailCollect = (props) => {
  const {
    btnText = "Get Course & Free Trial",
    btnSize = "M",
    position = "left",
    smallGreyBottomText,
    isLoading,
    emailValue,
    onEmailChange,
    onSubmit,
    onError,
    onKey,
  } = props;
  return (
    <div className="ControlsEmailCollect">
      <div
        className={`ControlsEmailCollect__controls ControlsEmailCollect__controls-${position}`}
      >
        <input
          type="email"
          placeholder={!onError ? "example@amzscout.net" : onError}
          className={`ControlsEmailCollect__input ${onError ? "error" : ""}`}
          value={emailValue}
          onChange={onEmailChange}
          onKeyDown={onKey}
        />
        <button
          type="button"
          className={`ControlsEmailCollect__bt ControlsEmailCollect__bt-${btnSize}`}
          onClick={onSubmit}
          disabled={isLoading}
        >
          {btnText}
        </button>
      </div>
      {smallGreyBottomText ? (
        <div className="ControlsEmailCollect__info">{smallGreyBottomText}</div>
      ) : null}
    </div>
  );
};

ControlsEmailCollect.propTypes = {
  btnText: PropTypes.string,
  btnSize: PropTypes.string,
  position: PropTypes.string,
  isLoading: PropTypes.bool,
  emailValue: PropTypes.string,
  onEmailChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onKey: PropTypes.func,
  onError: PropTypes.string,
};

export default ControlsEmailCollect;
