import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const ButtonCustom = (props) => {
  const { text, onClick } = props;

  return (
    <button type="button" className="ButtonCustom" onClick={onClick}>
      {text}
    </button>
  );
};
ButtonCustom.defualtProps = {
  text: "Try for free",
};

ButtonCustom.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ButtonCustom;
