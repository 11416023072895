import React, { useContext, useState } from "react";
import { MediaAuthContext } from "../../../../store/context/media-auth-context";
import { PAGE_OPTIONS, PAGES } from "../../../../helpers/constants";
import PG_L_HTSOA_HOME_INTRO_CONTENT from "./index.content";
import { getTrueClasses } from "../../../../helpers";
import ControlsEmailCollectContainer from "../../../../containers/common/forms/ControlsEmailCollectContainer";
import RedirectedButton from "../../../common/Forms/emailCollectRedirectedButton";
import "./index.scss";

const youtubeUrl = "https://www.youtube.com/embed/td5I2Aey2kI?autoplay=1";

const HomeIntro = () => {
  const {
    media: { isDesktop },
    auth: { isAuth },
  } = useContext(MediaAuthContext);

  const [isShowText, setIsShowText] = useState(false);
  const [isShowVideo, setIsShowVideo] = useState(false);
  const {
    IntroBgTab,
    IntroBgTab2x,
    IntroBgMob,
    IntroBgMob2x,
    IntroBgOne,
    webpIntroBgOne,
  } = PG_L_HTSOA_HOME_INTRO_CONTENT;

  return (
    <section className="PgLHTSOA-HomeIntro">
      <div className="container">
        <div className="PgLHTSOA-HomeIntro-row">
          <div className="PgLHTSOA-HomeIntro-info">
            <div className="PgLHTSOA-HomeIntro__title">
              Learn How to Sell on Amazon and Find the{" "}
              <span className="orange">Best Products</span>
            </div>
            <div className="PgLHTSOA-HomeIntro__subtitle">
              <b>Receive a detailed guide</b> and toolset for finding profitable
              products and selling them.
            </div>
            {isAuth ? (
              <RedirectedButton
                href={PAGE_OPTIONS[PAGES.LP_HOW_SELL].REDIRECTED_URL}
                title="Try For Free"
                background={"orange"}
                isTopText={true}
                isIntro
                isShowText={isShowText}
              />
            ) : (
              <ControlsEmailCollectContainer
                smallGreyBottomText="Take your first step to financial freedom right now!"
                position={isDesktop ? "left" : "center"}
                onSuccess={() => setIsShowText(true)}
                page={PAGES.LP_HOW_SELL}
              />
            )}
          </div>
          <div className="PgLHTSOA-HomeIntro-screen">
            <picture>
              <source srcSet={webpIntroBgOne} type="image/webp" />
              <img
                className="PgLHTSOA-HomeIntro-screen__img"
                src={IntroBgOne}
                alt=""
              />
            </picture>
            {/* <iframe src={youtubeUrl} frameBorder="0"/> */}
            <div
              onClick={() => setIsShowVideo(true)}
              className={getTrueClasses(
                "PgLHTSOA-HomeIntro-screen_mic",
                !isShowVideo && "PgLHTSOA-HomeIntro-screen_screenPlay"
              )}
            >
              {isShowVideo ? (
                <iframe
                  src={youtubeUrl}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  title="youtube"
                />
              ) : (
                <picture>
                  <source
                    media="(min-width: 768px) and (max-width: 1199px)"
                    srcSet={`${IntroBgTab} 1x, ${IntroBgTab2x} 2x`}
                  />
                  <source
                    media="(max-width: 767px)"
                    srcSet={`${IntroBgMob} 1x, ${IntroBgMob2x} 2x`}
                  />
                  <source
                    media="(min-width: 1200px)"
                    srcSet={`${IntroBgTab} 1x, ${IntroBgTab2x} 2x`}
                  />
                  <img
                    className="PgLHTSOA-HomeIntro-screen_imgVideo"
                    src={IntroBgTab}
                    alt=""
                    height="158"
                    width="281"
                  />
                </picture>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeIntro;
